import agenda from "../src/assets/images/home-icon/agenda.png"
import map from "../src/assets/images/home-icon/map.png"
import vivaEngage from "../src/assets/images/home-icon/vivaEngage.png"
import gallery from "../src/assets/images/home-icon/gallery.png"
import survey from "../src/assets/images/home-icon/survey.png"

export const API_URL = "https://api.secondstage.app"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#EA4D95', '#E76E51'],
        home_question: ['#E76E51', '#EA4D95'],
        applausometro: ['#E5634A', '#E5448A'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#EA4D95', '#E76E51'],
        qna: ['#EA4D95', '#E76E51'],
    },
    primary: '#2566b0',
    secondary: '#263238',
    accent: '#0f4593',//36,70,130
    accent2: '#5f8b4a',//36,70,130
    accent3: '#c37648',//36,70,130
    text: '#eceff1',
    background:"#81b7e4",//125/168/204
    poll:{
        default:"#81b7e4",
        answered:"#cd98ee",
        correct: '#3A722D',
        wrong: '#DB0A16' //195,118,72
    }
}

export const homeButtons = [
    /*{id: "agenda", label: "Agenda", pageTitle: "Agenda", img: <img src={agenda} width={'45px'}/>},*/
    /*{id: "departures", label: "Departures", pageTitle: "Departures", img: <img src={agenda} width={'45px'}/>},
    {id: "map", label: "Map", pageTitle: "", img: <img src={map} width={'45px'}/>},*/
    /*{id: "survey", label: "Survey", pageTitle: "Survey", img: <img src={survey} width={'45px'}/>},*/
    {id: "vivaEngage", label: "Viva Engage", pageTitle: "VIVA ENGAGE", img: <img src={vivaEngage} width={'45px'}/>},
    {id: "gallery", label: "Photogallery", img: <img src={gallery} width={'45px'}/>}
    /*{id: "policy", label: "Policy", pageTitle: "Social Media policy", icon: <InfoOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />, img: <img src={policy} width={'25px'}/>},*/
    /*{id: "playlist", label: "Playlist", icon: <MusicNote color={"primary"} fontSize={'large'}/>},*/
]