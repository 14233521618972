import {Button, ListItemIcon, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {Download, MapRounded, OpenInNew} from "@mui/icons-material";
import React, {useState} from "react";

const FileDownload = ({file}) => {

    return (
        <Stack mt={3} direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
            <Typography>Biglietto viaggio</Typography>
            {file &&
                <FileMenu label={"Map"} file={file} fileName={'costa-navarino-dunes-map'}/>}
        </Stack>
    );
}

export const FileMenu = ({label, file, fileName, qr = false}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //const fileNameTemp = file.substring(file.lastIndexOf('/') + 1);
    //const _fileName = fileNameTemp.substring(0, fileNameTemp.indexOf('.'));

    return (<>
        <Button variant={"contained"} size={'small'}
                color={"primary"} sx={{fontWeight: "bold", width: qr === true ? 150 : 'inherit'}}
                startIcon={<MapRounded/>}
                onClick={handleClick}
        >
            {label}
        </Button>
        <Menu
            id="demo-customized-menu"
            MenuListProps={{
                "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem href={file} target={"_blank"} component={"a"}>
                <ListItemIcon>
                    <OpenInNew/>
                </ListItemIcon>
                Open
            </MenuItem>
            <MenuItem onClick={() => downloadFile(file, fileName)}>
                <ListItemIcon>
                    <Download/>
                </ListItemIcon>
                Download
            </MenuItem>
        </Menu>
    </>
    )
}

export function downloadFile(fileUrl, fileName) {
    const ext = fileUrl.substring(fileUrl.lastIndexOf('.') + 1);

    // Effettua una richiesta GET al file
    fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
            // Crea un URL oggetto per il blob
            const url = window.URL.createObjectURL(new Blob([blob]));

            // Crea un elemento <a> temporaneo
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.${ext}`); // Nome del file da scaricare

            // Aggiungi l'elemento al DOM
            document.body.appendChild(link);

            // Simula il click sul link per avviare il download
            link.click();

            // Rimuovi l'elemento dal DOM
            document.body.removeChild(link);
        })
        .catch(error => {
            console.error('Errore durante il download del file:', error);
        })
}