import React, {useEffect, useState} from "react";
import {auth, firebase, firestore} from "../firebase/clientApp";
import VoteContent from "../components/VoteContent";
import {useParams} from "react-router-dom";
import {Box, Container} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import axios from "axios";
import {API_URL} from "../config";
import pleshDark from "../theme/pleshDark";
import CountdownTimerWrapper from "../components/CountdownTimer";

export default function Vote() {
    const {id} = useParams()
    const [user, setUser] = useState(null);
    const [event, eventLoading, eventError] = useDocument(firestore.doc('users/plesh/events/' + id))
    const [userData, setUserData] = useState({displayName: '', teamName: ''})

    const [loading, setLoading] = useState(true);

    async function loginUser() {
        await auth.signInAnonymously().then(() => {}).catch(alert);
    }

    useEffect(async () => {
        setLoading(true)
        await loginUser()
        firebase.auth().onAuthStateChanged(async (user) => {
            setUser(user);
            await axios.post(`${API_URL}/events/${id}/users`,{user: user.uid})
                .then(res =>
                    setUserData({displayName: res.data.displayName ?? '', teamName: res.data.teamName ?? ''})
                )
                .catch()
        })
        setLoading(false)
    }, [])

    if (loading) return <></>

    return (
        <Box>
            {/*<Box sx={{height: '3rem', background: pleshDark.palette.primary.main}}></Box>
            {event?.data()?.timer &&
                <Box position={'fixed'} top={0} right={0}>
                    <CountdownTimerWrapper label={event.data().timer.label} initialTime={event.data().timer.duration} isLive={event.data().timer.startedAt}
                                       startedAt={event.data().timer.startedAt}/>
                </Box>*/}
            {(event && !eventLoading && !eventError) &&
                <Container maxWidth={'sm'} className={'vote'} style={{padding: 0, maxHeight: '100%'}}>
                    <VoteContent user={user} event={event} loading={loading} userData={userData} setUserData={setUserData}/>
                </Container>
            }
        </Box>
    )
}
