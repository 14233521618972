import {Box, Button, Typography} from "@mui/material";
import React from "react";

const LogisticsDepartures = () => {

    return (
        <Box mt={4} pb={2} px={4}>
            <Typography gutterBottom textAlign={'center'} variant={'h3'} color={'primary'}>
                Departures Info
            </Typography>
            <Typography gutterBottom textAlign={'center'} variant={'h5'} color={'primary'}>
                View logistical information for departures here
            </Typography>
            <Button variant={'contained'}
                    href={"/assets/lista_transfer.pdf"} target={'_blank'}
                    fullWidth
                    sx={{
                        marginTop: '20%',
                        minHeight: '100px',
                        fontWeight: 'bold',
                        borderRadius: '18px 0',
                        background: "#cb3458"
                    }}
            >
                Download
            </Button>
        </Box>
    )
}

export default LogisticsDepartures