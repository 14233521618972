import {Box, Button, Stack, Typography} from "@mui/material";
import React from "react";
import pleshDark from "../theme/pleshDark";

const SocialMediaPolicy = () => {

    return (
        <Box mt={4} pb={2} px={4}>
            <Typography gutterBottom textAlign={'center'} variant={'h3'} color={'primary'}>
                Social Media Policy
            </Typography>
            <Typography gutterBottom textAlign={'center'} variant={'h5'} color={'secondary'}>
                Lorem ipsum dolet
            </Typography>
            <Button variant={'contained'} href={"/assets/Bolton_Social_media_policy.pdf"} target={'_blank'}
                    fullWidth
                    sx={{
                        marginTop: '20%',
                        minHeight: '100px',
                        fontWeight: 'bold',
                        borderRadius: '18px 0',
                        background: pleshDark.palette.accent.secondary2
                    }}
            >
                VEDI POLICY
            </Button>
        </Box>
    )
}

export default SocialMediaPolicy