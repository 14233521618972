import {Box, Button, Typography} from "@mui/material";
import React from "react";

const Survey = () => {

    return (
        <Box mt={4} pb={2} px={4}>
            <Button variant={'contained'}
                    href={"https://survey-boltonbethedifference.2ndstage.app"} target={'_blank'}
                    fullWidth
                    sx={{
                        marginTop: '20%',
                        minHeight: '100px',
                        fontWeight: 'bold',
                        borderRadius: '18px 0',
                        background: "#cb3458"
                    }}
            >
                Survey
            </Button>
        </Box>
    )
}

export default Survey