import {Box, Button, Typography} from "@mui/material";
import React from "react";

const VivaEngage = () => {

    return (
        <Box mt={4} pb={2} px={4}>
            <Typography gutterBottom textAlign={'center'} variant={'h3'} color={'primary'}>
                Viva Engage
            </Typography>
            <Typography gutterBottom textAlign={'center'} variant={'h5'} color={'primary'}>
                Follow our event also on Viva Engage
            </Typography>
            <Button variant={'contained'}
                    href={"https://engage.cloud.microsoft/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxOTU2MjY2OTY3MDQif"} target={'_blank'}
                    fullWidth
                    sx={{
                        marginTop: '20%',
                        minHeight: '100px',
                        fontWeight: 'bold',
                        borderRadius: '18px 0',
                        background: "#cb3458"
                    }}
            >
                VIVA ENGAGE
            </Button>
        </Box>
    )
}

export default VivaEngage