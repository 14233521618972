import React from "react";
import {Avatar, Box, Button, Grow, Slide, Stack, Typography} from "@mui/material";

import axios from "axios";
import {useParams} from "react-router-dom";

import clap from '../assets/images/reactions/clap.png'
import up from '../assets/images/reactions/up.png'
import heart from '../assets/images/reactions/heart.png'
import confused from '../assets/images/reactions/confused.png'
import {emojisplosion} from "emojisplosion";
import {API_URL} from "../config";
import pleshDark from "../theme/pleshDark";
import {useMediaQuery} from "@material-ui/core";


const ReactionsLive = ({url, idPlaying, eventPlaying, user}) => {
    const {id} = useParams()

    async function postDataApplausi(e, type, emoji) {
        emojisplosion({
            emojiCount:5,
            emojis: [emoji],
            position: () => ({
                x: e.clientX,
                y: e.clientY,
            }),
        })
        await axios.post(`${API_URL}/${id}/reactions?type=${type}`, {user: user.uid})
            .then(r => {
                navigator.vibrate(200)
            })
            .catch(error => console.log(error))
    }

    const ButtonItem = ({type, img, emoji, color}) => (
        <Box id='clapButton' disabled={!eventPlaying} onClick={(e) => postDataApplausi(e, type, emoji)}
             justifyContent={'center'} alignItems={'center'}
             pt={'12px'}
             borderRadius={"0 16px"} border={'1.5px solid '+color}
             sx={{
                 bgcolor: pleshDark.palette.accent.main,//color,
                 width: 65,
                 height: 65,
                 cursor: 'pointer',
                 boxShadow: '6px 5px 6px 0px #37373761'}}
        >
            <img style={{margin: 'auto', height:'40px'}} src={img} />
        </Box>
    )

    const matches = useMediaQuery('(max-height:680px)');

    return (
        <Stack alignItems={'center'} sx={{position: 'absolute', bottom: 10, left: 0, right: 0}}>
            {/*<Typography gutterBottom variant={'caption'}>Send your reactions</Typography>*/}
            <Slide in direction={"up"} timeout={1000}>
                <Stack mx={4} mb={2} direction={'row'} display={'flex'} justifyContent={'space-between'} spacing={2}>
                    {
                        reactions.map((reaction) => (
                            <ButtonItem key={reaction.id} type={reaction.id} img={reaction.img} emoji={reaction.emoji} color={reaction.color}/>
                        ))
                    }
                </Stack>
            </Slide>
        </Stack>
    )
}
export default ReactionsLive

const reactions = [
    {
        id: 'up',
        emoji: '💪',
        img: up,
        color: '#7fd977',
    },
    {
        id: 'applausi',
        emoji: '🙌',
        img: clap,
        color: '#cd98ee'
    },
    {
        id: 'heart',
        emoji: '❤️',
        img: heart,
        color: '#ca3457'
    },
    {
        id: 'confused',
        emoji: '🤔',
        img: confused,
        color: '#fde357'
    }
]