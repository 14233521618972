import React, {useState, useEffect, useMemo} from 'react';
import {Box, Stack, Typography} from "@mui/material";
import {useMediaQuery} from "@material-ui/core";

const CountdownTimer = ({timer, label, initialTime, isLive, startedAt}) => {
    // Initial time in seconds (1 hour)
    const [timeRemaining, setTimeRemaining] = useState(initialTime);
    const matches = useMediaQuery('(max-height:680px)');

    const progress = useMemo(() => {
        if(!startedAt)
            return 0

        const res = ((new Date()).getTime() - (new Date(!!startedAt?.seconds ? (startedAt?.seconds * 1000) : startedAt)).getTime()) / 1000

        return res
    }, [startedAt])

    useEffect(() => {
        setTimeRemaining(Math.floor(initialTime - progress))
    }, [initialTime, progress])

    useEffect(() => {
        if(isLive && (timeRemaining > 0)) {
            timer = setInterval(() => {
                setTimeRemaining((time) => {
                    if (time === 0) {
                        clearInterval(timer);
                        return 0;
                    } else return time - 1;
                });
            }, 1000);
        } else {
            clearInterval(timer);
            //setTimeRemaining(initialTime)
        }
        return () => clearInterval(timer)
    }, [isLive, timeRemaining]);

    // Convert seconds to hours, minutes, and seconds
    const hours = Math.floor(timeRemaining / 3600);
    const minutes = Math.floor((timeRemaining % 3600) / 60);
    const seconds = timeRemaining % 60;

    return (
        <Stack px={2} margin={'auto'} display={'flex'} alignItems={'center'}>
            {label && <Typography>{label}</Typography>}
            <Typography gutterBottom className={timeRemaining <= 0 && 'flashing'} color={timeRemaining <= 0 ? 'error' : 'primary'}
                        variant={matches ? 'h4' : 'h3'} textAlign={'center'} fontWeight={'bold'}>
                {`${hours === 0 ? '' : hours > 0 ? (`${hours}:`) : '00:'}${minutes > 0 ? minutes : '00'}:${seconds > 0 ? seconds : '00'}`}
            </Typography>
        </Stack>
    );
};

const CountdownTimerWrapper = ({label, initialTime, isLive, startedAt}) => {
    let timer
    return (
        <CountdownTimer timer={timer} label={label} initialTime={initialTime} isLive={isLive} startedAt={startedAt}/>
    );
};

export default CountdownTimerWrapper;