import {Stack} from "@mui/material";
import React from "react";

const PhotoGallery = () => {
    return (
        <Stack>
            <iframe id="MemzoInt" src="https://memzo.ai/h/mq2gu9ze/13182/18972"
                    allow="camera *"
                    style={{border: 'none', width: '100vw', height: '85vh', overflowY: 'hidden', overflow: 'hidden'}}
                    title="Get Your Photos">

            </iframe>
        </Stack>
    )
}

export default PhotoGallery