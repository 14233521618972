import React, {useEffect, useState} from "react";
import {firestore} from "../../firebase/clientApp";
import {Box, Container, Stack, useTheme} from "@mui/material";
import {Textfit} from "react-textfit";
import {QuestionSwitch} from "./QuestionSwitch";
import pleshDark from "../../theme/pleshDark";
import DialogLayout from "../../layout/DialogLayout";

function QuestionBar({domanda}) {
    const theme = useTheme()
    return (
        <Container sx={{height: '25%'}}>
            <Box sx={{position: 'relative', width: '100%', height:'100%'}}>
                <Textfit mode={'multi'} min={10} max={30}
                         style={{
                             color: pleshDark.palette.primary.main,
                             //fontWeight: '400',
                             //fontFamily: 'Proto Grotesk',
                             width: '100%',
                             height: '100%',
                             textAlign: 'center',
                             display: 'flex',
                             alignItems: 'center',
                             justifyContent: 'center'
                         }}>
                    {domanda}
                </Textfit>
            </Box>
        </Container>
    )
}

function QuestionContent({elementPlaying, theme}) {
    return(
        <Container sx={{ position:'relative', paddingX: '2rem', textAlign:'center'}}>
            <QuestionSwitch elementPlaying={elementPlaying} theme={theme}/>
        </Container>
    )
}


export default function QuestionDialogContent({url, user,idPlaying,open, theme}) {
    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(()=>{
        idPlaying && getElementPlaying()
    }, [idPlaying])

    function getElementPlaying() {
        firestore.collection(url+'/queue').doc(idPlaying).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }
    return(
        <DialogLayout type={elementPlaying.tipo} title={elementPlaying.domanda} children={
            <Stack justifyContent={"space-between"} style={{height:'100%', zIndex:1}}>
                {/*<QuestionBar tipo={elementPlaying.tipo} colore={elementPlaying.colore} domanda={elementPlaying.domanda}/>*/}
                <QuestionContent elementPlaying={elementPlaying} user={user} theme={theme}/>
            </Stack>
        }/>
    )
}
