import {Box, Button, Dialog, DialogActions, ListItemIcon, Menu, MenuItem, Stack, Typography} from "@mui/material";
import map from "../assets/images/Navarino-Dunes-map.png"
import React, {useState} from "react";
import {downloadFile, FileMenu} from "./FileDownload";
import {Download, MapRounded, OpenInNew, SystemUpdate} from "@mui/icons-material";

const PlaceMap = () => {
    const [zoom, setZoom] = useState(false)
    const handleZoom = () => {
        setZoom(!zoom)
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Box mt={4} width={'100%'}
             // sx={{  backgroundImage: `url(${map})`,
             //        backgroundPosition: 'top',
             //        backgroundSize: 'contain',
             //        cursor: 'pointer',
             //        backgroundRepeat: 'no-repeat'}}
             //onClick={handleZoom}
        >
            <>
                <Typography textAlign={'center'} variant={'h5'} color={'primary'}>
                    The browsable map of the resort is available on the official app
                </Typography>
                <Stack py={2} direction={'row'} justifyContent={'center'} spacing={1}>
                    <>
                        <Button size={'small'} variant={"contained"} color={"primary"} sx={{fontWeight: "bold"}}
                                startIcon={<SystemUpdate/>}
                            //href={biglietto2}
                            //target={'_blank'}
                                onClick={handleClick}
                            //onClick={() => downloadFile(biglietto2,'ticket-2-we-play-4-real')}
                        >
                            NAVARINO DUNES APP
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                                "aria-labelledby": "demo-customized-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem href={'https://apps.apple.com/us/app/costa-navarino-greece/id1458397325?l=el&ls=1'}
                                      target={"_blank"} component={"a"}>
                                <ListItemIcon>
                                    <OpenInNew/>
                                </ListItemIcon>
                                iPhone - App Store
                            </MenuItem>
                            <MenuItem href={'https://play.google.com/store/apps/details?id=com.mhospitality.costanavarino&hl=it&pli=1'}
                                      target={"_blank"} component={"a"}>
                                <ListItemIcon>
                                    <OpenInNew/>
                                </ListItemIcon>
                                Android - Google Play Store
                            </MenuItem>
                        </Menu>
                    </>
                </Stack>
            </>
            <img src={map} width={'100%'} style={{objectFit: 'contain'}} onClick={handleZoom}/>
            {<Dialog open={zoom} onClose={handleZoom} fullWidth maxWidth={'xl'}>
                <img src={map} width={'100%'}/>
                <DialogActions>
                    <FileMenu label={'Download Map'} fileName={'Navarino-Dunes-map'} file={map}/>
                </DialogActions>
            </Dialog>}
        </Box>
    )
}

export default PlaceMap