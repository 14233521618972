import React from "react";
import {Box, Typography} from "@mui/material";

const Agenda = ({event}) => {
    console.log("Agenda event:",event)
    return (
        <Box mt={4} pb={2} px={4} style={{minHeight: '15vh'}}>
            <Box sx={{m: 1}} style={{/*, whiteSpace: 'pre-line'*/}}>
                <Typography textAlign={'center'} variant={'h3'} color={'primary'}>
                    Agenda
                </Typography>
                {event.data().contenutoAgenda &&
                  <Typography fontSize={'body1'} color={'primary'}
                              dangerouslySetInnerHTML={{__html: event.data().contenutoAgenda}}>

                  </Typography>
              }
                {/*
                  <div style={{textAlign: "left"}}>

                      <h3>25 Sept. 2024</h2>
                      <p>20:00-22:00 - Dinner at Division16</p>

                      <div style={{height: '1px', width: '100%', background: 'white'}}/>

                      <h2>Day 1 – 26 Sept. 2024</h2>
                      <div>09:00-12:30 - Introduction and plenary session @ Great Hall meeting room</div>
                      <p>12:30-14:00 - Lunch break @ Morias Restaurant</p>
                      <p>14:00-17:30 - Plenary session @ Great Hall meeting room</p>
                      <p>19:00 - Meeting point @ Westin Hotel Reception | Transfer to dinner location</p>
                      <p>20:00 - Typical Greek dinner | Dress code: casual</p>

                  </div>
              */}
                {/*
                    <div>
                        <h3>25 Sept. 2024</h2>
                        <table>
                            <tr>
                                <td className="time">20:00/22:00</td>
                                <td>Dinner at Division16</td>
                            </tr>
                        </table>

                        <h2>Day 1 – 26 Sept. 2024</h2>
                        <table>
                            <tr>
                                <td className="time">09:00/12:30</td>
                                <td>Introduction and plenary session @ Great Hall meeting room</td>
                            </tr>
                            <tr>
                                <td className="time">12:30/14:00</td>
                                <td>Lunch break @ Morias Restaurant</td>
                            </tr>
                            <tr>
                                <td className="time">14:00/17:30</td>
                                <td>Plenary session @ Great Hall meeting room</td>
                            </tr>
                            <tr>
                                <td className="time">19:00</td>
                                <td>Meeting point @ Westin Hotel Reception | Transfer to dinner location</td>
                            </tr>
                            <tr>
                                <td className="time">20:00</td>
                                <td>Typical Greek dinner | Dress code: casual</td>
                            </tr>
                        </table>
                    </div>
                */}
                {/*ag.map(a =>
                  <Box sx={{textAlign: 'left'}} style={{overflow: "visible"}}>
                      <Typography component={'div'} variant={"subtitle1"} fontWeight={"bold"}
                                  textTransform={"uppercase"} color={'primary'}>
                          {a.ora}
                      </Typography>
                      <Typography dangerouslySetInnerHTML={{__html: a.contenuto}}>

                      </Typography>
                  </Box>
              )*/}
            </Box>
        </Box>

    )
}

export default Agenda
